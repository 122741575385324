import 'semantic-ui-css/semantic.min.css'
import { Container, Form, Dimmer, Loader, Message, Modal, Button } from 'semantic-ui-react'
import { useEffect, useState } from 'react'
import { uid } from 'uid'
import StepComp from '../components/Step/Step'
import MessageComp from '../components/Message/Message'

function App() {
  const [name, setName] = useState([])
  const [date, setDate] = useState([])
  const [mount, setMount] = useState('')
  const [error, setError] = useState(false)
  const [open, setOpen] = useState(false)
  const [send, setSend] = useState(false)
  const [info, setInfo] = useState('')
  const [form, setForm] = useState({})

  //'https://server.time.vitarts.ru'
  const SERVER = 'https://server.time.vitarts.ru'

  // Отправка данных на сервер
  const submitFunc = () => {
    setInfo('Идет отправка данных...')
    setOpen(true)
    setSend(true)

    fetch(SERVER, {
      method: 'POST',
      body: JSON.stringify(form),
      headers: {
        'Content-type': 'application/json; charset=UTF-8',
      },
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.submit) {
          setInfo('Данные отправлены!')
          setForm({ date: '', time: '', name: '', comment: '' })
        } else {
          setInfo('Ошибка, попробуйте еще раз.')
        }
        setSend(false)
      })
      .catch((err) => {
        setInfo('Ошибка сервера! Попробуйте позже.')
        setSend(false)
        console.log(err)
      })
  }

  // Получаем данные при загрузки приложения
  useEffect(() => {
    fetch(SERVER)
      .then((res) => res.json())
      .then((data) => {
        setName(
          data.name.map((item) => {
            return { key: uid(), text: item, value: item }
          })
        )

        setDate(
          data.time.map((item) => {
            return { key: uid(), text: item, value: item }
          })
        )

        setMount(data.mount)

        // Устанавливаем дату если она есть в списке
        const found = data.time.find((element) => element === new Date().toLocaleDateString())
        if (!!found) {
          setForm({ date: found })
        }
      })
      .catch((err) => {
        setError(true)
        console.log(err)
      })
  }, [])

  console.log(form)

  return (
    <Container>
      {!!name.length ? (
        <>
          <Message attached>
            <div className="logo">
              <img src="/apple-touch-icon.png" alt="" />
              <div>
                <Message.Header>ГПД - Время выхода</Message.Header>
                <p>{`2В за ${mount} г.`}</p>
              </div>
            </div>
          </Message>
          <Form
            size="large"
            className="attached fluid segment"
            onSubmit={() => {
              submitFunc()
            }}
          >
            <Form.Select
              onChange={(e, result) => {
                setForm({ ...form, name: result.value })
              }}
              icon="user"
              fluid
              label="Ученик:"
              options={name}
              value={form.name}
            />
            <Form.Select
              onChange={(e, result) => {
                setForm({ ...form, date: result.value })
              }}
              icon="calendar"
              fluid
              label="Дата:"
              options={date}
              value={form.date}
            />
            <Form.Input
              onChange={(e) => {
                setForm({ ...form, time: e.target.value })
              }}
              type="time"
              icon="time"
              value={form.time || ''}
              fluid
              label="Время выхода:"
              placeholder="Время выхода"
            />

            <Form.Input
              onChange={(e) => {
                setForm({ ...form, comment: e.target.value })
              }}
              type="text"
              icon="comment"
              value={form.comment || ''}
              fluid
              label="Комментарий:"
            />

            <StepComp form={form} />

            <br />
            <br />

            {!!form.name && !!form.date && !!form.time ? (
              <Form.Button primary>Отправить</Form.Button>
            ) : (
              <Form.Button disabled primary>
                Заполните все данные
              </Form.Button>
            )}
          </Form>

          <MessageComp />
        </>
      ) : (
        <>
          <Dimmer active inverted>
            <Loader>
              Загрузка данных...
              {error && (
                <>
                  <p>Ошибка сервера! Попробуйте позже.</p>
                  <Button color="blue" onClick={() => window.location.reload()}>
                    Обновить!
                  </Button>
                </>
              )}
            </Loader>
          </Dimmer>
        </>
      )}

      <Modal basic onOpen={() => setOpen(true)} open={open} size="small">
        <Modal.Content className="modal">
          {send ? (
            <p>{info}</p>
          ) : (
            <>
              <p>{info}</p>
              <Button color="blue" onClick={() => setOpen(false)}>
                Хорошо
              </Button>
            </>
          )}
        </Modal.Content>
      </Modal>
    </Container>
  )
}

export default App
