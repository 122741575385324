import React from 'react'
import { Message } from 'semantic-ui-react'

function MessageComp() {
  return (
    <Message info>
      <Message.Header>Таблица с данными</Message.Header>
      <p>
        <a
          target="_blank"
          href="https://docs.google.com/spreadsheets/d/1btkZyG-RSEGLDhfROxbcPPowIac_FUxV3vZP_IQMmUw/edit?pli=1#gid=1196225843"
        >
          Актуальные данные доступны в таблице
        </a>
      </p>
    </Message>
  )
}

export default MessageComp
