import { Step } from 'semantic-ui-react'

const StepComp = ({ form }) => {
  const { name, date, time } = form

  return (
    <Step.Group size="small" ordered widths={3}>
      <Step completed={!!name}>
        <Step.Content>
          <Step.Title>Ученик</Step.Title>
          <Step.Description>{name ? name : 'Выберите имя'}</Step.Description>
        </Step.Content>
      </Step>

      <Step completed={!!date}>
        <Step.Content>
          <Step.Title>Дата</Step.Title>
          <Step.Description>{date ? date : 'Выберите дату'}</Step.Description>
        </Step.Content>
      </Step>

      <Step completed={!!time}>
        <Step.Content>
          <Step.Title>Время</Step.Title>
          <Step.Description>{time ? time : 'Заполните время'}</Step.Description>
        </Step.Content>
      </Step>
    </Step.Group>
  )
}

export default StepComp
